@import "./styles/theme-colors.scss";
@import "@mobiscroll/react5/dist/css/mobiscroll.scss";
@import "@mobiscroll/react/dist/css/mobiscroll.scss";
@import "~bootstrap/scss/bootstrap";
@import "react-circular-progressbar/dist/styles.css";
@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400;1,500;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

$font-family: "Poppins", sans-serif;
$thin: 100;
$extra-light: 200;
$light: 300;
$normal: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;
$bolder: 900;

.ag-theme-alpine {
  @include ag-theme-alpine(
    (
      foreground-color: $light-text-color,
      alpine-active-color: $primary-bg-color,
      borders: false,
      header-background-color: $white,
      secondary-foreground-color: $accent-color,
      subheader-background-color: $white,
      border-color: transparent,
      font-family: (
        $font-family,
      ),
      font-size: 15px,
    )
  );
  height: 100% !important;
  a {
    color: #000000 !important;
  }
  .ag-root-wrapper,
  .ag-root-wrapper-body,
  .ag-tab-guard {
    height: 100%;
  }
  .ag-body-viewport {
    flex: 1 1 auto;
    overflow: auto;
  }
  .ag-header-cell-label {
    font-family: $font-family;
    font-size: 15px;
    font-weight: $medium;
    color: $light-text-color;
  }
  .ag-cell {
    font-size: 15px;
    font-weight: $normal;
    color: $text-color;
  }

  .todo_list .ag-theme-alpine .ag-row-selected {
    background: transparent;
    .toDo {
      text-decoration: line-through;
    }
  }
  .docg-patient-sidebar {
    border-radius: "30px";
  }

  .docg-table-action {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-evenly;
    align-items: center;
    .btn.btn-link,
    .mbsc-mobiscroll.mbsc-btn.docg-btn-icon {
      margin: 0 10px 0 0;
      transition: unset;
      border: 0 !important;
      min-width: unset;
      &:hover,
      &:active,
      &:focus {
        transition: unset;
        border: 0 !important;
      }
      img {
        height: 20px;
      }
    }
  }
}
