@import "./styles/theme-colors.scss";
// mobiscroll.customTheme('mobiscroll-red', 'mobiscroll');

$font-family: "Poppins", sans-serif;
$thin: 100;
$extra-light: 200;
$light: 300;
$normal: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;
$bolder: 900;

body {
  margin: 0;
  font-size: 14px;
  font-variant: normal;
  font-weight: $normal;
  font-style: normal;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 7px !important;
  height: 6px !important;
  margin-right: 10px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 5px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:hover {
  background: rgba(5, 5, 5, 0.23) !important;
}

.text-decoration-line {
  text-decoration: line-through;
}

.f-14 {
  font-size: 14px !important;
}

.fw-500,
.font-weight-medium {
  font-weight: 500;
}

.mh-0 {
  max-height: 0% !important;
}

.mh-1 {
  max-height: 1% !important;
}

.mh-30 {
  max-height: 30% !important;
}

.mh-50 {
  max-height: 50% !important;
}

.jc-sb {
  justify-content: space-between;
}

.jc-c {
  justify-content: center;
}

.bg-pink {
  background: $accent-color !important;
}

.bg-purple {
  background: $title-color !important;
}

.border-radius-17 {
  border-radius: 17px !important;
}

.border-bottom-gray-2 {
  border-bottom: 2px solid #d9d9d9 !important;
}

.border-bottom-error-2 {
  border-bottom: 1px solid $danger !important;
}

* {
  font-family: "Poppins", serif;
}

.ag-theme-alpine .ag-header-cell-resize::after {
  display: none !important;
}
.patient-wrapper {
  .docg-card {
    box-shadow: 0px 0px 12px 0px rgb(0 0 0 / 40%) !important;
    form {
      margin: 0 !important;
    }
    .mbsc-ios.mbsc-page {
      background: #fff;
    }
  }
  .docg-tabs {
    background: #fff;
  }
  .mbsc-ms-top.mbsc-scv-ctx {
    position: relative;
  }
}

.sidebar-logged {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-flow: column;
  border-bottom: 1px solid rgba(180, 180, 180, 0.3);
  padding: 15px;

  .DoctorName {
    font-family: "Poppins", serif;
    font-size: 17px;
  }
}
.mbsc-ios.mbsc-fr-pointer .mbsc-sc-itm-sel {
  color: $accent-color !important;
}

.wrapper {
  background: $primary-bg-color;
  .main-panel {
    border-radius: 35px 0px 0px 35px !important;
    background: #f0f3f5;
    min-height: 100%;
    padding-top: 70px;
    height: 100%;
    flex: auto;
    overflow: hidden;
    .content {
      display: flex;
      flex-direction: column;
      justify-content: normal;
      background: #f0f3f5;
      max-height: 100%;
      min-height: 100%;
      border-radius: 0 0 0 25px;
      box-sizing: border-box;
      overflow: auto;
      margin: 0 !important;
      padding: 15px 0;
    }
  }
}

.btn.docg-button {
  background-color: $accent-color;
  border-color: $accent-color;
  color: $white;
  font-size: 20px;
  text-align: center;
  border-radius: 30px;
  min-width: 83px;
  height: 60px;
  padding: 16px 35px;
  box-shadow: 0 2px 2px 0 $accent-color;
  text-transform: capitalize;
  width: 100%;
  box-shadow: 0 2px 2px 0 #c06c84;
  font-family: $font-family;
  font-weight: $semi-bold;
  &:hover {
    background-color: $secondary;
    border-color: $secondary;
    opacity: 0.7;
  }
}

.mbsc-mobiscroll.mbsc-btn {
  background-color: $accent-color;
  border-color: $accent-color;
  color: $white;
  font-size: 14px;
  font-family: $font-family;
  font-weight: $normal;
  text-align: left;
  border-radius: 30px;
  box-shadow: 0 2px 2px 0 $accent-color;
  &:hover {
    background-color: $secondary;
    border-color: $secondary;
    opacity: 0.7;
  }
}

.mbsc-mobiscroll.mbsc-btn.docg-button-outline {
  background-color: transparent;
  border: 1px solid $accent-color;
  color: $accent-color;
  box-shadow: none;
}

.mbsc-mobiscroll.mbsc-btn.docg-button-link {
  background-color: transparent;
  border-color: transparent;
  border: 0;
  color: $accent-color;
  box-shadow: none;
  &:hover {
    background-color: transparent;
    border-color: none;
    opacity: 0.7;
    box-shadow: none;
    border: 0;
  }
}

.btn.theme-button {
  background-color: $accent-color !important;
  border-color: $accent-color;
  color: $white;
  font-size: 14px;
  text-align: center;
  border-radius: 30px;
  min-width: 83px;
  height: 35px;
  padding: 0;
  box-shadow: 0 2px 2px 0 $accent-color;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  text-transform: none;
}
.btn.theme-button:hover {
  background-color: $accent-color;
  border-color: $accent-color;
  opacity: 0.9;
  box-shadow: none;
}
.btn.theme-button:focus {
  background-color: $accent-color;
  border-color: $accent-color;
  opacity: 1;
  box-shadow: none;
}

.sidebar {
  background-color: $primary-bg-color;
  width: 16rem;
  height: 100%;
  z-index: 1032;
  transition: width 0.3s;
  display: flex;
  flex-direction: column;
  box-shadow: none;
  .sidebar-wrapper {
    margin-top: 1rem;
    overflow-y: auto;
    padding-left: 1rem;
    flex: 1 auto;
    .dropdown-menu-container {
      margin-top: 0.5rem;
      margin-left: 0.5rem;
      display: block;
      background-color: rgba(5, 5, 5, 0.23);
      padding-left: 0.5rem;
      width: 87%;
      margin-bottom: 1.2rem;
      border-radius: 0.25rem;
      padding-top: 1rem;
      height: auto;
      padding-left: 0.5rem;
      padding-bottom: 0.1rem;
      a {
        margin: 0 !important;
        p {
          font-size: 0.8rem;
          font-weight: normal;
        }
        i {
          font-size: 17px;
        }
      }
    }
  }
  .logo {
    padding: 15px 0px;
    margin: 0 auto;
    display: flex;
    position: relative;
    z-index: 4;
    justify-content: center;
    align-items: center;
    &:after {
      width: 100%;
      right: 0;
    }
  }
  .nav {
    display: flex;
    width: 100%;
    .nav-item {
      display: flex;
      flex: 1 auto;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      a {
        display: flex;
        flex: auto;
        font-family: $font-family;
        font-size: 17px;
        font-weight: $medium;
        letter-spacing: 0;
        font-style: normal;
        font-variant: normal;
        flex-direction: row;
        align-items: center;
        width: 100%;
        padding: 5px 5px 5px 15px;
        margin: 10px 15px 0;
        color: $white;
        // margin-left: 0;
        i,
        img {
          margin-right: 2px;
          color: #fff;
          width: 18px;
          height: 18px;
        }
        p {
          font-family: $font-family;
          font-size: 0.95rem;
          font-weight: $medium;
          letter-spacing: 0;
          line-height: 20.4px;
          font-style: normal;
          font-variant: normal;
          width: 100%;
          margin-left: 10px;
        }
        .addIconWrap {
          // width: 100%;
          img {
            float: right;
          }
        }
      }
      a.active {
        border: 1px solid #fff;
      }
      &:hover {
        a {
          color: $white;
        }
      }
    }
  }

  .sidebar-wrapper {
    .dropdown-menu-container {
      margin-top: 0.5rem;
      margin-left: 0.65rem;
      display: block;
      background-color: rgba(5, 5, 5, 0.23);
      padding-left: 0.5rem;
      width: 87%;
      margin-bottom: 0.25rem;
      border-radius: 0.25rem;
      height: auto;
      padding: 0.5rem 1rem;
      padding-top: 1rem;
      a {
        margin: 0.25rem 0 !important;
        p {
          font-size: 0.8rem;
          font-weight: normal;
        }
        i {
          font-size: 17px;
          line-height: 17px;
          height: 17px;
        }
      }
    }
  }

  .sidebar-footer {
    position: relative;
    bottom: 0px;
    margin: 0px 15px;
    h3 {
      font-family: "Poppins", serif;
      font-size: 12px;
      color: #968ba3;
      white-space: nowrap;
      span {
        color: #fff;
      }
    }
  }
}

.width-decrease-sidebar {
  padding-left: 0 !important;
  .nav .nav-item a {
    padding: 0.4em !important;
    margin: 0.35em 1.2em;
    justify-content: center;
  }

  .sidebar-footer {
    padding-top: 1rem;
  }
}

.docg-mobile-view {
  display: none;
  @media screen and (max-width: 1200px) {
    display: block;
  }
}

.docg-top-bar-left {
  display: flex;
  flex-basis: auto;
  width: 100%;

  .navbar-top-first {
    display: flex;
  }

  .navbar-nav {
    flex-direction: row;
  }
  .appointment-top-bar {
    .pulse-appointment {
      margin-right: 0.4rem;
    }
    img {
      margin-left: 0.3rem;
    }
  }
}

@media screen and (max-width: 1220px) {
  .docg-top-bar-left {
    .appointment-top-bar {
      .pulse-appointment {
        margin-right: 0rem;
        margin-left: 0.1rem;
      }
      img {
        margin-left: 0.3rem;
      }
    }
  }
}

@media screen and (max-width: 1160px) {
  .docg-top-bar-left {
    .appointment-top-bar {
      display: none;
    }
  }
}

@media screen and (max-width: 1200px) {
  .sidebar .nav .nav-item a {
    padding: 5px;
  }
  .docg-top-bar-left {
    display: flex !important;
    flex-basis: auto;
  }
}

@media screen and (max-width: 1200px) {
  .sidebar {
    left: 0;
    :before {
      background: none;
    }
    transform: translate3d(0, 0, 0);
  }
}

@media screen and (max-width: 1024px) {
  .container-fluid {
    .navbar-toggler {
      flex: 1;
    }
  }
  .docg-top-bar-left {
    flex: 4;
  }
}

.navbar-nav .doctor-logs {
  position: relative;
  margin-left: 10px;
  span {
    position: absolute;
    top: 9px;
    font-family: "Poppins", serif;
    font-weight: 600;
    color: $accent-color;
  }
}

.min-sidebar {
  width: calc(100% - 90px) !important;
}

.pulse-appointment {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-left: 2rem;
  border-radius: 50%;
  top: 0.1rem;
  position: relative;
  background: #d35502 0% 0% no-repeat padding-box;
  opacity: 1;
  box-shadow: orange;
  animation: pulseAppointment 2s infinite;
}
.pulse-appointment:hover {
  animation: none;
}

@-webkit-keyframes pulseAppointment {
  0% {
    -webkit-box-shadow: 0 0 0 0 #d17537;
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px #d17537;
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 #d17537;
  }
}
@keyframes pulseAppointment {
  0% {
    -moz-box-shadow: 0 0 0 0 #d17537;
    box-shadow: 0 0 0 0 #d17537;
  }
  70% {
    -moz-box-shadow: 0 0 0 10px #d17537;
    box-shadow: 0 0 0 10px #d17537;
  }
  100% {
    -moz-box-shadow: 0 0 0 0 #d17537;
    box-shadow: 0 0 0 0 #d17537;
  }
}

/* side bar css................. */
.width-decrease-sidebar {
  width: 95px !important;
  display: flex !important;
  flex-direction: column;
}
.add-border-docname {
  border-bottom: 1px solid rgb(0 0 0 / 10%) !important;
}
.def-div-content a {
  padding: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.margin-remove {
  margin-right: 0px !important;
}
.width-decrease-icon {
  width: 20px !important;
  height: 20px !important;
  position: absolute;
  right: 12px;
}

nav.navbar.navbar-expand-lg.navbar-transparent.navbar-absolute.fixed-top {
  /* border-bottom:1px solid #0000001f; */
  border-bottom: 1px solid rgba(0, 0, 0, 10%);
}
.headerContentImg {
  border-left: 1px solid #b5b5b5;
  margin-top: 4px;
  margin-right: 5px;
  padding-left: 5px;
}
.headerContentPera {
  margin-top: 10px;
  font-size: 18px;
  font-family: "Poppins";
  font-weight: $medium;
  margin-right: 10px;
  color: #484848 !important;
}
.headerContentTime {
  color: #d35502 !important;
  font-weight: $semi-bold;
}
.HeaderContentVideo {
  margin-top: -5px;
  margin-left: 5px;
}
.navbar-fixed-top {
  display: flex;
  flex: 0 0 auto;
  background: #f0f3f5 0% 0% no-repeat padding-box;
  width: 100%;
  position: absolute !important;
  height: 70px !important;
  left: 260px;
  padding: 0;
  transition: all 0.2s ease-in-out;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #000;
    opacity: 0.1;
    top: 67px;
    left: 0;
  }
  .navbar-brand {
    margin: 0 !important;
  }
}

.min-sidebar {
  .navbar-fixed-top {
    width: 100%;
    height: 90px;
  }
}

.appointment-top-bar {
  margin: 0 10px;
  .text {
    font-size: 17px;
    font-weight: 500;
    line-height: 30px;
    span {
      color: red;
    }
    img {
      width: 30px;
    }
  }
}

.card.docg-card {
  background-color: transparent;
  box-shadow: 0px 0px 15px #00000029;
  overflow: hidden;
  &:hover {
    .docg-card-title {
      background: #dee2e6;
    }
  }
  .docg-card-header {
    background-color: $header-bg-color !important;
    border-radius: 17px 17px 0 0;
    padding: 6px 20px;
    min-height: 60px;
  }
  .docg-card-title {
    font-size: 20px;
    font-weight: $medium;
    background-color: transparent;
    border-radius: 0;
    border: 0;
    color: $primary-bg-color;
    align-items: center;
  }
  .docg-card-divider {
    border: 1px solid #707070;
    margin: 6px -20px;
    display: flex;
  }
  .docg-card-space {
    display: flex;
    margin-top: 12px;
  }
  .docg-card-body {
    border-radius: 17px 17px 17px 17px !important;
    background-color: $white;
  }
  .widget-card-header {
    background-color: $accent-color;
    border-radius: 17px 17px 0 0;
    padding: 3px 20px;
    height: 30px;
    min-height: 30px;
    text-align: center;
  }
  .widget-card-body {
    padding: 15px !important;
  }
  // .ag-theme-alpine .ag-root-wrapper {
  //   border-radius: 0 0 17px 17px;
  //   border: none;
  // }
  .docg-table-search {
    display: flex;
    flex: 1 1 auto;
    height: 35px;
    margin-right: 8px;
    max-width: 185px;
    border: 0;
    input {
      border-radius: 30px;
      padding: 8px 15px;
      width: 100%;
      min-width: 150px;
      border: 0;
    }
  }
  .mbsc-switch.docg-table-switch {
    background: transparent;
    padding: 0;
    height: 100%;
    display: flex;
    align-items: center;
    &:before {
      border: 0;
    }
    &:after {
      border: 0;
    }
    .mbsc-ios.mbsc-switch-track-left {
      left: 0;
    }
    .mbsc-switch-track {
      height: 1.5em;
      width: 2.6em;
      background: #d88146;
      position: static !important;
      margin: 0;
    }
    .mbsc-switch-handle {
      top: 0.4em;
      left: 0.43em;
      width: 1.2em;
      background: #fff;
      height: 1.2em;
    }
    input:checked + .mbsc-switch-track {
      background: #35be89;
      .mbsc-switch-handle {
        background: #fff;
      }
    }
  }

  .docg-tabs.card-header-tabs.nav-tabs {
    margin: 0;
    padding: 0;
    .nav-link.active,
    .nav-item.show .nav-link {
      border: 0;
    }
  }
  .docg-popup-container {
    .mbsc-popup-content {
      padding: 0;
    }
  }
}

h4.docg-title,
span.docg-title {
  font-family: $font-family;
  font-size: 46px;
  font-weight: $semi-bold;
  color: $primary-bg-color;
  line-height: 58px;
  margin: 0;
}

.CircularProgressbar.docg-circular-progressbar {
  .CircularProgressbar-background {
    fill: #ececec;
  }
  .CircularProgressbar-trail {
    stroke: #ffffff;
  }
  .CircularProgressbar-path {
    stroke: $warning !important;
  }
}

.CircularProgressbar.docg-circular-progressbar.CircularProgressbar-path-missed {
  .CircularProgressbar-path {
    stroke: $danger !important;
  }
}

.CircularProgressbar.docg-circular-progressbar.CircularProgressbar-path-completed {
  .CircularProgressbar-path {
    stroke: $primary !important;
  }
}
.CircularProgressbar.docg-circular-progressbar.CircularProgressbar-path-upcoming {
  .CircularProgressbar-path {
    stroke: $info !important;
  }
}

.mbsc-mobiscroll.mbsc-card.docg-card {
  background-color: transparent;
  box-shadow: 0px 0px 15px #00000029;
  border-radius: 17px;
  margin: 0 0 0.75em 0;
  &:hover {
    .docg-card-title {
      background: transparent !important;
    }
  }
  .docg-card-header {
    background-color: $header-bg-color;
    border-radius: 17px 17px 0 0;
    padding: 6px 20px;
    min-height: 30px;
  }
  .docg-card-title {
    font-size: 20px;
    font-weight: $medium;
    background-color: transparent;
    border-radius: 0;
    border: 0;
    color: $primary-bg-color;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-right: 8px;
    }
  }
  .docg-card-divider {
    border: 1px solid #707070;
    margin: 12px -20px;
    display: flex;
  }
  .docg-card-space {
    display: flex;
    margin-top: 12px;
  }
  .docg-card-content {
    background-color: $white;
    .mbsc-mobiscroll.mbsc-page {
      background: transparent;
      .mbsc-windows.mbsc-ms-c {
        background: transparent;
      }
    }
  }
  .widget-card-header {
    background-color: $accent-color;
    border-radius: 17px 17px 0 0;
    padding: 3px 20px;
    height: 30px;
    min-height: 30px;
    text-align: center;
  }
  .widget-card-body {
    padding: 15px !important;
  }
  // .ag-theme-alpine .ag-root-wrapper {
  //   border-radius: 0 0 17px 17px;
  //   border: none;
  // }
  .docg-table-search {
    display: flex;
    flex: 1 1 auto;
    height: 35px;
    max-width: 185px;
    margin: 0 8px 0 0;
    input {
      border-radius: 30px;
      padding: 8px 15px;
      width: 100%;
      min-width: 150px;
      background-color: #fff;
      border: 0;
    }
  }
  .mbsc-switch.docg-table-switch {
    background: transparent;
    padding: 0;
    height: 35px;
    &:before {
      border: 0;
    }
    &:after {
      border: 0;
    }
    .mbsc-ios.mbsc-switch-track-left {
      left: 0;
    }
  }
  .docg-tabs.card-header-tabs.nav-tabs {
    margin: 0;
    padding: 0;
    .nav-link.active,
    .nav-item.show .nav-link {
      border: 0;
    }
  }
  .docg-popup-container {
    .mbsc-popup-content {
      padding: 0;
    }
  }
}

.mbsc-mobiscroll.mbsc-card.docg-card.docg-card-border {
  box-shadow: none;
  border: 1px solid $header-bg-color;
}

.docg-pagination-page-size {
  position: absolute;
  bottom: -12px;
  left: 15px;
  z-index: 99;
}

.mbsc-mobiscroll.mbsc-btn.docg-btn-icon {
  font-size: 12px;
  font-weight: $normal;
  line-height: 24px;
  text-transform: capitalize;
  background: transparent;
  color: $accent-color;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 12px auto;
  padding: 12px 0;
  min-width: auto;
  height: auto;
  border: 0;
  box-shadow: none;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    border: 0;
    box-shadow: none;
    color: $accent-color;
    outline: none;
  }
  &:not(.btn-just-icon):not(.btn-fab) .fa {
    margin-bottom: 5px;
    background: linear-gradient(90deg, #6d5c7c 0%, #9482a4 99%);
    border-radius: 16px;
    width: 45px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }
}
// .ag-row .ag-cell {
//   display: flex; /* align horizontal */
//   -items: center;
// }

.rounded-border {
  border: 2px solid gray;
  padding: 2px;
}

.ag-header-cell-label {
  justify-content: center;
}

.ag-theme-alpine .ag-ltr .ag-cell {
  text-align: -webkit-center;
}

.mbsc-mobiscroll.mbsc-btn.docg-btn-link-icon {
  font-size: 12px;
  font-weight: $normal;
  line-height: 24px;
  text-transform: capitalize;
  background: transparent;
  color: $accent-color;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 0;
  box-shadow: none;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    border: 0;
    box-shadow: none;
    color: $accent-color;
    outline: none;
  }
}

.mbsc-mobiscroll.mbsc-input {
  .mbsc-control.docg-form-control {
    font-size: 14px;
    font-family: $font-family;
    font-weight: $medium;
    line-height: 16.8px;
    color: #929292;
    min-width: 150px;
    border-radius: 12px;
    background-color: #dee2e6;
    border: 1px solid #dee2e6;
    padding: 12px 25px;
    min-height: 46px;
  }
  .mbsc-label {
    top: 20px;
    left: 0;
  }
}

.medical-record {
  .medical-record-title {
    font-family: $font-family;
    font-size: 16px;
    font-weight: $medium;
    color: $primary-bg-color;
    margin: 0;
    padding: 0;
    text-align: left;
    line-height: 20px;
  }
  .medical-record-list {
    .mbsc-scv-item.mbsc-btn-e {
      background: $bg-color;
    }
    .mbsc-ms-item.mbsc-ms-item-sel {
      background: #fff !important;
      border: none !important;
      padding: 0 !important;
    }
    display: flex;
    flex: auto;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 0 0 15px;
    padding: 0;
    list-style: none;
    text-align: center;
    .medical-record-item {
      display: flex;
      flex: auto;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      list-style: none;
      text-align: center;
      border: 0;

      .medical-record-item-title {
        font-family: $font-family;
        font-size: 0.8rem;
        font-weight: $semi-bold;
        color: $light-text-color;
        line-height: 16px;
        margin: 0 0 5px;
      }
      .medical-record-item-data {
        font-family: $font-family;
        font-size: 18px;
        font-weight: $semi-bold;
        color: $primary-bg-color;
        opacity: 1;
        line-height: 22px;
        .medical-record-item-symbol {
          font-family: $font-family;
          font-size: 10px;
          font-weight: $semi-bold;
          color: $light-text-color;
          line-height: 12px;
          vertical-align: super;
        }
      }
    }
  }
}

.history-scroll {
  display: flex;
  flex-wrap: nowrap !important;
  flex-direction: row;
  overflow-y: hidden;
  overflow-x: auto;
  .history-card {
    margin: 0 0 20px;
    .docg-card {
      margin: 1rem 0.5rem !important;
      border: 1px solid #d2d2d2;
      height: 8rem;
      max-height: 8rem;
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }
    .docg-card-title {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 40px;
      height: 45px;
      min-width: 151px;
      width: 100%;
      padding: 0;
    }
    .docg-card-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background: #dee2e6;
      color: $primary-bg-color;
      padding: 0;
      font-family: $font-family;
      font-size: 16px;
      font-weight: $medium;
      text-transform: capitalize;
      line-height: 20px;
      margin: 0;
      border: 0;
      width: 100%;
      box-shadow: none;
      img {
        margin-left: 8px;
        max-height: 8px;
        max-width: 8px;
      }
    }
    .docg-card-content {
      overflow: auto;
      flex: 1 1 auto;
      h6 {
        color: #565656;
        margin: 7px 0;
        font-size: 11px;
        font-family: "Poppins", serif;
        text-align: center;
      }
      h5 {
        color: #6c5b7b;
        text-align: center;
        margin: 7px 0;
        font-size: 12px;
        font-family: "Poppins", serif;
        text-align: center;
        font-weight: $medium;
      }
    }
  }

  .history-card-timeline {
    border: 2px solid rgba(108, 91, 123, 0.3);
    margin: 0 0 10px 0;
    position: relative;
    text-align: center;
    .history-card-timeline-pointer {
      background: $primary-bg-color;
      width: 10px;
      height: 10px;
      position: absolute;
      border-radius: 100%;
      bottom: -5px;
      z-index: 2;
      opacity: 1;
    }
  }
}

.mbsc-ms-txt {
  .docg-tabs.mbsc-comp {
    .mbsc-ms-item.mbsc-ms-item-sel,
    .mbsc-ms-item.mbsc-ms-ic {
      font-family: $font-family;
      font-size: 14px;
      font-weight: $medium;
      color: #585858;
      line-height: 16px;
      border: 0;
      display: flex;
      flex: 1 auto;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-around;
      align-items: center;
      &::before {
        position: relative;
        color: #585858;
        top: unset;
        left: unset;
        right: unset;
        padding-right: 6px;
      }
      &:hover,
      &:active,
      &:focus {
        color: $primary-bg-color;
        border-bottom-color: #c16d85;
        &::before {
          color: $primary-bg-color;
        }
      }
    }

    .mbsc-scv-item.mbsc-btn-e {
      display: flex;
      flex-direction: row;
      font-family: $font-family;
      justify-content: center;
      align-items: center;
      font-size: 1.2rem;
      font-weight: $medium;
      color: #585858;
      margin-right: 0px;
      padding: 5px 7px !important;
      line-height: 20px;
      border: 0;
      border-bottom: 2px solid rgba(220, 220, 220);
      img {
        height: 1.5rem;
        max-width: 30px;
        margin-right: 1rem;
      }
    }
    .mbsc-ms-item.mbsc-ms-item-sel {
      color: $primary-bg-color;
      border-bottom: 2px solid #c16d85;
      &::before {
        color: $primary-bg-color;
      }
    }
  }
}

.symptom {
  .actions {
    position: absolute;
    right: 20px;
    top: 30px;
    bottom: 40px;
    .mbsc-mobiscroll.mbsc-btn {
      background: transparent;
      border: 0;
      margin: 0;
      padding: 0;
      box-shadow: none;
      font-size: 22px;
      color: #929292;
      &:last-child {
        background: $white;
        padding: 0 8px;
        .mbsc-btn-ic {
          padding: 0;
        }
      }
    }
  }
}

.notes {
  .docg-wrapper {
    border: 1px solid $bg-color;
    .docg-toolbar {
      border: 0;
      border-bottom: 1px solid $bg-color;
    }
  }
}

.invoice-detail-header {
  background-color: #f0f0f0;
}

.docg-popup-container {
  .mbsc-popup {
    margin-left: auto;
    max-height: var(--app-height) !important;
  }
}

@media (max-width: 720px) {
  .min-sidebar {
    width: 100% !important;
  }
}

@media all and (max-width: 1200px) {
  docg-hide {
    display: none !important;
  }
  .sidebar {
    transition: all 1s;
  }
  .sidebar-backdrop {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1031;
  }
}

@media all and (max-width: 1200px) {
  .navbar-fixed-top {
    width: 100%;
    height: 90px;
    left: 0;
  }
  .sidebar::before {
    background-color: inherit !important;
    z-index: -1;
  }
}

@media all and (max-width: 567px) {
  .docg-top-bar-left .navbar-nav {
    .switch,
    .doctor-logs {
      display: none;
    }
  }
  .docg-top-bar-left .navbar-wrapper {
    display: none;
  }
}

.mbsc-ios.mbsc-input:before,
.mbsc-ios.mbsc-input:after,
.mbsc-ios.mbsc-form .mbsc-control-w:before,
.mbsc-ios.mbsc-form .mbsc-control-w:after {
  border: none !important;
  border-color: none !important;
}

.mbsc-ios.mbsc-textarea {
  height: auto;
}

textarea {
  min-height: 0 !important;
  font-family: "Poppins", serif !important;
  // height: 1em !important;
}

.mbsc-ios.mbsc-input {
  background: none !important;
}

.medical-record-list {
  .mbsc-ms-item.mbsc-ms-item-sel {
    padding: 0 !important;
  }
}
.ag-paging-row-summary-panel {
  display: none;
}

.image-cropper {
  .ReactCrop__image {
    max-height: 320px;
  }
}