$primary-bg-color: #6c5b7b;
$bg-color: #f0f3f5;
$white: #ffffff;
$header-bg-color: #dee2e6;
$accent-color: #c06c84;
$text-color: #444444;
$light-text-color: #7b7b7b;
$primary: #01a162;
$secondary: $accent-color;
$warning: #f9af25;
$streach-text: #818181;
$info: #376498;
$danger: #ff1a00;
$title-color: #6c5b7b;
$title-bg-color: #dee2e6;

// Global Calendar

.mbsc-ic.icon {
  font-family: inherit;
  font-weight: normal;
}
.mbsc-ic.icon:before {
  font-family: "Font Awesome\ 5 Free", "Font Awesome\ 5 Brands",
    "Font Awesome 5 Pro";
}
.mbsc-ic.icon.fas:before,
.mbsc-ic.icon.fa:before {
  font-weight: 900;
}
/* Start Calendar */
$mbsc-calendar-background-light: $bg-color;
$mbsc-calendar-background-dark: $bg-color;
$mbsc-calendar-text-light: $light-text-color;
$mbsc-calendar-text-dark: $text-color;
$mbsc-calendar-accent-light: $accent-color;
$mbsc-calendar-accent-dark: $accent-color;
$mbsc-calendar-border-light: $primary-bg-color;
$mbsc-calendar-border-dark: $primary-bg-color;
$mbsc-calendar-mark-light: $accent-color;
$mbsc-calendar-mark-dark: $accent-color;

$mbsc-ios-accent: $accent-color;
$mbsc-material-accent: $accent-color;
$mbsc-mobiscroll-accent: $accent-color;

// iOS
$mbsc-ios-primary: $accent-color;
$mbsc-ios-calendar-background: $bg-color;
$mbsc-ios-calendar-text: $text-color;
$mbsc-ios-calendar-accent: $accent-color;
$mbsc-ios-calendar-border: $primary-bg-color;
$mbsc-ios-calendar-mark: $accent-color;
$mbsc-ios-calendar-event: $accent-color;

// Windows
$mbsc-windows-primary: $accent-color;
$mbsc-windows-calendar-background: $bg-color;
$mbsc-windows-calendar-text: $text-color;
$mbsc-windows-calendar-accent: $accent-color;
$mbsc-windows-calendar-border: $primary-bg-color;
$mbsc-windows-calendar-mark: $accent-color;
$mbsc-windows-calendar-event: $accent-color;

$mbsc-windows-dark-calendar-background: $bg-color;
$mbsc-windows-dark-calendar-text: $text-color;
$mbsc-windows-dark-calendar-accent: $accent-color;
$mbsc-windows-dark-calendar-border: $primary-bg-color;
$mbsc-windows-dark-calendar-mark: $accent-color;
$mbsc-windows-dark-calendar-event: $accent-color;

// Material

$mbsc-material-primary: $accent-color;
$mbsc-material-calendar-background: $bg-color;
$mbsc-material-calendar-text: $text-color;
$mbsc-material-calendar-accent: $accent-color;
$mbsc-material-calendar-border: $primary-bg-color;
$mbsc-material-calendar-mark: $accent-color;
$mbsc-material-calendar-event: $accent-color;

$mbsc-material-dark-calendar-background: $bg-color;
$mbsc-material-dark-calendar-text: $text-color;
$mbsc-material-dark-calendar-accent: $accent-color;
$mbsc-material-dark-calendar-border: $accent-color;
$mbsc-material-dark-calendar-mark: $accent-color;
$mbsc-material-dark-calendar-event: $accent-color;

/* End Calendar */

/* Start Card */
$mbsc-calendar-background-light: $header-bg-color;
$mbsc-calendar-text-light: $primary-bg-color;
$mbsc-calendar-background-dark: $header-bg-color;
$mbsc-calendar-text-dark: $primary-bg-color;

// iOS

$mbsc-ios-calendar-background: $header-bg-color;
$mbsc-ios-calendar-text: $primary-bg-color;
$mbsc-ios-dark-calendar-background: $header-bg-color;
$mbsc-ios-dark-calendar-text: $primary-bg-color;

// Windows

$mbsc-windows-calendar-background: $header-bg-color;
$mbsc-windows-calendar-text: $primary-bg-color;
$mbsc-windows-dark-calendar-background: $header-bg-color;
$mbsc-windows-dark-calendar-text: $primary-bg-color;

// Material

$mbsc-material-calendar-background: $header-bg-color;
$mbsc-material-calendar-text: $primary-bg-color;
$mbsc-material-dark-calendar-background: $header-bg-color;
$mbsc-material-dark-calendar-text: $primary-bg-color;

// Mobiscroll

$mbsc-mobiscroll-form-background: $header-bg-color;
$mbsc-mobiscroll-form-text: $primary-bg-color;
$mbsc-mobiscroll-dark-form-background: $header-bg-color;
$mbsc-mobiscroll-dark-form-text: $primary-bg-color;

/* End Card */

/* Start Form */

$mbsc-form-background-light: $white;
$mbsc-form-background-dark: $white;
$mbsc-form-text-light: $light-text-color;
$mbsc-form-text-dark: $text-color;
$mbsc-form-accent-light: $accent-color;
$mbsc-form-accent-dark: $accent-color;
$mbsc-form-border-light: $primary-bg-color;
$mbsc-form-border-dark: $primary-bg-color;
$mbsc-form-mark-light: $accent-color;
$mbsc-form-mark-dark: $accent-color;

// iOS

$mbsc-ios-form-background: $white;
$mbsc-ios-form-text: $text-color;
$mbsc-ios-form-accent: $accent-color;
$mbsc-ios-form-border: $primary-bg-color;
$mbsc-ios-form-mark: $accent-color;
$mbsc-ios-form-event: $accent-color;

$mbsc-ios-dark-form-background: $white;
$mbsc-ios-dark-form-text: $text-color;
$mbsc-ios-dark-form-accent: $accent-color;
$mbsc-ios-dark-form-border: $primary-bg-color;
$mbsc-ios-dark-form-mark: $accent-color;
$mbsc-ios-dark-form-event: $accent-color;

// Windows

$mbsc-windows-form-background: $white;
$mbsc-windows-form-text: $text-color;
$mbsc-windows-form-accent: $accent-color;
$mbsc-windows-form-border: $primary-bg-color;
$mbsc-windows-form-mark: $accent-color;
$mbsc-windows-form-event: $accent-color;

$mbsc-windows-dark-form-background: $white;
$mbsc-windows-dark-form-text: $text-color;
$mbsc-windows-dark-form-accent: $accent-color;
$mbsc-windows-dark-form-border: $primary-bg-color;
$mbsc-windows-dark-form-mark: $accent-color;
$mbsc-windows-dark-form-event: $accent-color;

// Material

$mbsc-material-form-background: $white;
$mbsc-material-form-text: $text-color;
$mbsc-material-form-accent: $accent-color;
$mbsc-material-form-border: $primary-bg-color;
$mbsc-material-form-mark: $accent-color;
$mbsc-material-form-event: $accent-color;

$mbsc-material-dark-form-background: $white;
$mbsc-material-dark-form-text: $text-color;
$mbsc-material-dark-form-accent: $accent-color;
$mbsc-material-dark-form-border: $accent-color;
$mbsc-material-dark-form-mark: $accent-color;
$mbsc-material-dark-form-event: $accent-color;

// Mobiscroll
$mbsc-mobiscroll-primary: $accent-color;
$mbsc-mobiscroll-form-background: $white;
$mbsc-mobiscroll-form-text: $text-color;
$mbsc-mobiscroll-form-accent: $accent-color;
$mbsc-mobiscroll-form-border: $primary-bg-color;
$mbsc-mobiscroll-form-mark: $accent-color;
$mbsc-mobiscroll-form-event: $accent-color;

$mbsc-mobiscroll-dark-form-background: $white;
$mbsc-mobiscroll-dark-form-text: $text-color;
$mbsc-mobiscroll-dark-form-accent: $accent-color;
$mbsc-mobiscroll-dark-form-border: $accent-color;
$mbsc-mobiscroll-dark-form-mark: $accent-color;
$mbsc-mobiscroll-dark-form-event: $accent-color;

/* End Form */

// Button Color

$mbsc-ios-secondary: $secondary;
$mbsc-material-secondary: $secondary;
$mbsc-mobiscroll-secondary: $secondary;
$mbsc-windows-secondary: $secondary;

$mbsc-ios-form-accent: $accent-color;
$mbsc-ios-form-text: $title-color;

// flex designs

@mixin docg-flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@mixin docg-flex-col {
  display: flex;
  flex-direction: row;
  align-items: center;
}

// include the ios theme
$mbsc-ios-theme: true;
