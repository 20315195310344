@import "./styles/theme-colors.scss";
/* Input CSS */

/* autoprefixer : on */
.wrapper {
  height: var(--app-height);
}
.mbsc-ios.mbsc-textfield-wrapper {
  margin: 0;
}
.docg-shadow {
  box-shadow: 0px 0px 12px 0px rgb(0 0 0 / 40%) !important;
}
.mbsc-ios .mbsc-switch-track,
.mbsc-ios .mbsc-switch-track:after {
  background: #d88146 !important;
}
.mbsc-ios.mbsc-switch input:checked + .mbsc-switch-track {
  background: #35be89 !important;
}
.flex-3 {
  flex: 3;
}
.mbsc-ms-top.mbsc-ms-c,
.mbsc-ms-bottom.mbsc-ms-c {
  z-index: auto !important;
}
button {
  background: transparent;
}

.card.docg-card::hover .card-title {
  background: red !important;
}

.row,
.mbsc-row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.docg-vh-standard {
  height: calc(var(--app-height) - 100px);
  overflow: auto;
}
.docg-h-standard {
  height: calc(100% - 100px) !important;
  overflow: auto;
}
.ag-react-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mbsc-ms-top.mbsc-scv-ctx,
.mbsc-ms-bottom.mbsc-scv-ctx,
.mbsc-ms-top.mbsc-ms-c,
.mbsc-ms-bottom.mbsc-ms-c {
  position: relative !important;
}

.purple-bold {
  font-size: 16px;
  font-weight: 600;
  color: #6c5b7b;
  font-family: "Poppins";
}

.purple-medium {
  font-size: 16px;
  font-weight: 500;
  color: #6c5b7b;
  font-family: "Poppins";
}
.docg-box-shadow {
  box-shadow: 0px 0px 12px 0px rgb(0 0 0 / 40%) !important;
}
.pink-div {
  font-weight: 400;
  background: $accent-color;
  color: #fff;
  font-family: "Poppins";
  border-radius: 1em;
}

.mbsc-form-grid .mbsc-textfield-wrapper-box,
.mbsc-form-grid .mbsc-textfield-wrapper-outline {
  margin: 0;
}
.mbsc-ios .mbsc-checkbox-box,
.mbsc-ios .mbsc-checkbox-box:after {
  border-color: $accent-color !important;
}
.mbsc-ios.mbsc-select-icon.mbsc-ltr,
.mbsc-ios.mbsc-select-icon.mbsc-rtl {
  top: auto;
  bottom: 0;
  right: 0.3em;
}

.mbsc-input.mbsc-control-w.mbsc-ltr,
.mbsc-input.mbsc-control-w.mbsc-rtl {
  display: flex;
  flex-direction: row;
  align-items: center;
  //   border: 0;
  //   border-bottom: 1px solid #aaaaaa;
}

.mbsc-input.mbsc-label-floating.mbsc-control-w.mbsc-ltr,
.mbsc-input.mbsc-label-underline.mbsc-control-w.mbsc-ltr,
.mbsc-textfield-wrapper-stacked,
.mbsc-ios.mbsc-textfield-wrapper {
  border-bottom: 1px solid #aaaaaa;
  margin-bottom: 1px !important;
  background: transparent !important;
}

.ag-theme-alpine .ag-root-wrapper {
  border-radius: 17px !important;
}

.records-display {
  display: flex;
  align-items: center;
  width: 10rem;
  position: absolute;
  bottom: 0rem;
  height: 48px;
  z-index: 1;
  order: 1;
  margin-left: 2.8rem;
  span {
    font-weight: 500;
    color: #6d6d6d;
  }
  .record-selection-dropdown {
    position: relative;
    cursor: pointer;
    height: 14px;
    input {
      border-bottom: 1.5px solid #c06c84;
      border-radius: 0;
      font-weight: 500;
      width: 3rem;
      color: #c06c84;
      cursor: pointer;
      padding: 0 0 0 4px;
    }
    i {
      position: absolute;
      top: 0.25rem;
      right: 0.4rem;
      color: #c06c84;
    }
  }
}

@media all and (max-width: 567px) {
  .records-display {
    bottom: -1rem;
    // right: -2.8rem;
  }
}

.docg-form .mbsc-input-wrap input {
  padding-top: 1.8em;
  padding-left: 0;
  color: #777;
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins", serif;
}
// color: #929292;
//     font-size: 1rem;
//     font-weight: 600;
//     font-family: "Poppins", serif;
//     left: 0;

.docg-save-btn {
  background: #c8768e;
  padding: 5px 25px;
  display: flex;
  align-items: center;
  border-radius: 25px;
  opacity: 1;
  &:disabled {
    opacity: 0.5;
  }
  img {
    height: 20px;
    margin-right: 7px;
  }
  span {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    font-family: "Poppins", serif;
  }
}
.docg-save-btn {
  background: #c8768e;
  padding: 5px 25px;
  display: flex;
  align-items: center;
  border-radius: 25px;
  opacity: 1;
  &:disabled {
    opacity: 0.5;
  }
  img {
    height: 20px;
    margin-right: 7px;
  }
  span {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    font-family: "Poppins", serif;
  }
}
.docg-outline-btn {
  border: 1px solid $accent-color;
  padding: 5px 20px;
  display: inline-flex;
  gap: 4px;
  align-items: center;
  border-radius: 25px;
  width: fit-content;
  height: inherit;
  color: $accent-color;
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins", serif;
  span {
    color: $accent-color;
    font-size: 14px;
    font-weight: 500;
    font-family: "Poppins", serif;
  }
  img {
    height: 20px;
  }
}
.docg-pink-btn {
  color: #c8768e;
  display: flex;
  align-items: center;
  border-radius: 25px;
  img {
    height: 1.25rem;
    margin-right: 0.25rem;
    width: auto !important;
  }
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins", serif;
}
.docg-pink-btn-submit {
  color: #c8768e;
  border: 1px solid #c8768e;
  padding: 5px 25px;
  display: flex;
  align-items: center;
  border-radius: 25px;
  img {
    height: 1.25rem;
    margin-right: 0.25rem;
  }
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins", serif;
}
// .docg-patient-form {
// margin-top: 3rem;

.mbsc-ios.mbsc-error-message {
  padding: 0;
  line-height: 0.7rem;
  position: relative;
  top: 0;
}

.mbsc-ios.mbsc-label-floating.mbsc-input .mbsc-label ~ .mbsc-input-wrap input,
.mbsc-ios.mbsc-label-floating.mbsc-input
  .mbsc-label
  ~ .mbsc-input-wrap
  .mbsc-color-input,
.mbsc-ios.mbsc-label-inline.mbsc-input .mbsc-label ~ .mbsc-input-wrap input,
.mbsc-ios.mbsc-label-inline.mbsc-input
  .mbsc-label
  ~ .mbsc-input-wrap
  .mbsc-color-input,
.mbsc-ios.mbsc-select.mbsc-ltr {
  height: 3.2em !important;
  padding-top: 1.8em !important;
  padding-left: 0;
  color: #777;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", serif;
  background: transparent;
}
.mbsc-ios.mbsc-ltr.mbsc-input .mbsc-label {
  padding-left: 0;
}
.mbsc-ios.mbsc-ltr.mbsc-label-floating.mbsc-input .mbsc-label,
.mbsc-ios.mbsc-ltr.mbsc-label-inline.mbsc-input .mbsc-label,
.mbsc-ios.mbsc-ltr.mbsc-input .mbsc-label,
.mbsc-ios.mbsc-label-box-stacked,
.mbsc-ios.mbsc-label-outline-stacked,
.mbsc-ios.mbsc-label-box-floating,
.mbsc-ios.mbsc-label-outline-floating {
  color: #929292;
  font-size: 1rem;
  font-weight: 400;
  font-family: "Poppins", serif;
  top: 0.875em !important;
  left: 0 !important;
  width: auto;
  max-width: initial;
}

.mbsc-ios.mbsc-label-box-stacked,
.mbsc-ios.mbsc-label-outline-stacked {
  transform: translateY(-0.25em) scale(1) !important;
  font-size: 0.75em;
  // left: -0.75rem !important;
}

.docg-select {
  select {
    border: none;
    border-bottom: 1px solid #aaaaaa;
    margin-bottom: 1px !important;
    width: 100%;
    -webkit-appearance: auto;
    color: #929292;
    font-size: 1rem;
    font-weight: 600;
    font-family: "Poppins", serif;
    height: 2.8em;
  }
}
.docg-input-wrapper {
  display: flex;
  align-items: center;
  height: 30px;
  position: relative;
  > div {
    display: flex;
    align-items: center;
    height: 30px;
    position: relative;
  }
  div {
    color: #929292;
    font-size: 14px;
    font-weight: 600;
    font-family: "Poppins", serif;
  }
}
.docg-day-gap-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  .docg-day-gap {
    max-width: 5rem;
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
    label {
      color: #c06c84;
      font-size: 0.8rem;
      font-weight: 500;
      font-family: "Poppins", serif;
    }
    input {
      max-width: 3rem;
      border: none;
      border-bottom: 1px solid #aaaaaa;
      color: #000;
      font-size: 12px;
      font-weight: 600;
      font-family: "Poppins", serif;
    }
  }
  .docg-day-select {
    select {
      width: 100%;
      -webkit-appearance: auto;
      border: none;
      border: none;
      border-bottom: 1px solid #aaaaaa;
      color: #929292;
      font-size: 12px;
      font-weight: 600;
      font-family: "Poppins", serif;
    }
  }
}

.mbsc-ios.mbsc-input:before {
  border: 0 !important;
}
.mbsc-input.mbsc-label-inline.mbsc-control-w.mbsc-ltr {
  border-bottom: 1px solid #aaaaaa;
  margin-bottom: 1px !important;
  background: transparent !important;
}

.mbsc-ios.mbsc-ltr.mbsc-input .mbsc-label {
  padding-left: 0;
}

.mbsc-ios.mbsc-textarea textarea {
  // height: 100%;
  font-size: 14px;
  line-height: 20px;
  color: #929292 !important;
  font-family: "Poppins", serif;
  flex: 1;
  padding: 0;
}
.details {
  label {
    font-family: "Poppins", serif;
    font-size: 13px;
    font-weight: 600;
    color: #565656;
  }
  input {
    border: 0;
    border-bottom: 1px solid #aaaaaa;
    color: #929292;
    font-size: 14px;
    font-weight: 600;
    font-family: "Poppins", serif;
  }
}
// }
/* The container */
.docg-radio-label {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin: 0;
  width: max-content;
  cursor: pointer;
  font-size: 14px;
  font-family: "Poppins", serif;
  font-weight: 500;
  color: #929292;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default radio button */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: auto;
  }
  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: auto;
    left: 0;
    height: 19px;
    width: 19px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #ccc;
  }
  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background-color: #fff;
  }

  /* When the radio button is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: #fff;

    border: 1px solid $accent-color;
  }
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  /* Show the indicator (dot/circle) when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }
  /* Style the indicator (dot/circle) */
  .checkmark:after {
    top: 2px;
    left: 2px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: $accent-color;
  }
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
  /* border: 1px solid blue; */
}

.slider.round:before {
  border-radius: 50%;
}
.slider:after {
  content: "NEW";
  color: #fff;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 42%;
  font-size: 14px;
  font-weight: 500;
}

input:checked + .slider:after {
  content: "Revised";
  color: #fff;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 35%;
  font-size: 14px;
  font-weight: 500;
}
input:checked + .slider.slider-po-show:before {
  -webkit-transform: translateX(65px);
  -ms-transform: translateX(65px);
  transform: translateX(65px);
}
.slider.slider-po-show:after {
  content: "Offline";
  left: 3.5em;
}
input:checked + .slider.slider-po-show:after {
  content: "Online";
  left: 2.5em;
}
.slider.active.slider-po-show:after {
  content: "Inactive";
  left: 4em;
}
input:checked + .slider.active.slider-po-show:after {
  content: "Active";
  left: 2.5em;
}
.switch {
  position: relative;
  display: inline-block;
  width: 90px;
  height: 25px;
  margin: 0;
}
.switch input {
  opacity: 0;
}
.docg-vaccination-switch {
  width: 72px;
  .slider {
    background-color: #929292;
  }
  .slider.slider-po-show:after {
    content: "Due";
    left: 3em;
  }
  input:checked + .slider {
    background-color: #1aab73;
  }
  input:checked + .slider.slider-po-show:before {
    transform: translateX(45px);
  }
  input:checked + .slider.slider-po-show:after {
    content: "Done";
    left: 2em;
  }
}
.docg-cancel-reason {
  .title {
    color: #929292;
    font-size: 14px;
    font-weight: 500;
  }
}
.mbsc-ios.mbsc-textfield-wrapper-underline,
.mbsc-input.mbsc-control-w.mbsc-ltr,
.mbsc-input.mbsc-control-w.mbsc-rtl {
  background: none;
  border-bottom: 1px solid #aaaaaa;
  .mbsc-ios.mbsc-label-inline {
    line-height: 20px;
    padding-left: 0.1em;
    color: #929292;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
  .mbsc-ios.mbsc-select {
    background: transparent;
    height: 2.5em;
    color: #929292;
    font-size: 14px;
    font-weight: 400;
  }

  .mbsc-ios.mbsc-label-floating {
    color: #929292;
    font-size: 1rem;
    font-weight: 400;
    font-family: "Poppins", serif;
    top: 0.875em !important;
    left: 0 !important;
    width: auto;
    max-width: initial;
  }
  .mbsc-ios.mbsc-textfield-floating {
    height: 3.2em !important;
    padding-top: 1.8em !important;
    padding-left: 0;
    color: #777;
    font-size: 14px;
    font-weight: 400;
    font-family: "Poppins", serif;
  }
  .mbsc-ios.mbsc-label-floating-active.mbsc-label {
    transform: translateY(-0.75em) scale(0.75);
  }
  .mbsc-ios.mbsc-textfield {
    padding: 0;
  }
}

.mbsc-ios.mbsc-label-floating.mbsc-input.mbsc-label-floating-active.mbsc-ic-left
  .mbsc-label,
.mbsc-ios.mbsc-label-floating.mbsc-input.mbsc-label-floating-active.mbsc-ic-right
  .mbsc-label {
  transform: translateY(-0.75em) scale(0.75) !important;
}

.textarea-wrap {
  border: 1px solid #707070;
  margin: 1rem 0rem;
  padding: 0.5rem 1rem 1rem 1rem;
  // padding-top: 0rem;
  border-radius: 20px;
  position: relative;
  & > span {
    position: absolute;
    background: #fff;
    padding: 0 0.5em;
    color: #727272;
    font-size: 1rem;
    font-weight: 600;
    font-family: "Poppins", serif;
    top: -0.875em !important;
    width: auto;
    max-width: initial;
  }
  .mbsc-ios.mbsc-textarea,
  .mbsc-ios.mbsc-textarea textarea {
    padding-left: 0.5em;
    color: #777;
    font-size: 14px;
    font-weight: 400;
    font-family: "Poppins", serif;
  }
  .mbsc-row {
    margin: 0;
  }
  .mbsc-ios.mbsc-input .mbsc-label,
  .mbsc-ios.mbsc-progress .mbsc-label {
    width: auto;
    max-width: fit-content;
  }
}

.docg-title {
  color: #565656;
  font-size: 18px;
  font-weight: 500;
}

/* Absolute Center docg-spinner */
.loading {
  position: fixed;
  z-index: 9999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));

  background: -webkit-radial-gradient(
    rgba(20, 20, 20, 0.8),
    rgba(0, 0, 0, 0.8)
  );
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: docg-spinner 150ms infinite linear;
  -moz-animation: docg-spinner 150ms infinite linear;
  -ms-animation: docg-spinner 150ms infinite linear;
  -o-animation: docg-spinner 150ms infinite linear;
  animation: docg-spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes docg-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes docg-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes docg-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes docg-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* checkbox */

.custom-repeat-checkbox-cont .mbsc-checkbox.mbsc-form-control-wrapper {
  display: inline-block;
  padding: 0.875em 2em !important;
  background: transparent;
  font-family: "Poppins", serif;
  font-size: 1em;
  color: $accent-color;
}

.custom-repeat-checkbox-cont .mbsc-checkbox .mbsc-checkbox-box {
  left: 0;
  width: 20px;
  height: 20px;
  margin-top: -10px;
}

.custom-repeat-checkbox-cont .mbsc-checkbox.mbsc-form-control-wrapper:after,
.custom-repeat-checkbox-cont .mbsc-checkbox.mbsc-form-control-wrapper:before {
  border: 0;
}

.custom-repeat-checkbox-cont .mbsc-checkbox .mbsc-checkbox-box:after {
  width: 10px;
  height: 6px;
  top: 4px;
  left: 3px;
}

.custom-condition-cont .mbsc-radio.mbsc-form-control-wrapper {
  padding-top: 8px;
  padding-bottom: 8px;
}

.custom-condition-cont .mbsc-ios.mbsc-form-control-wrapper:after,
.custom-condition-cont .mbsc-ios.mbsc-form-control-wrapper:before {
  border-color: transparent;
}

.custom-condition-cont .mbsc-radio-box {
  top: 40%;
}

.custom-condition-cont .mbsc-material.mbsc-radio-box:after,
.custom-condition-cont .mbsc-windows.mbsc-radio-box:after {
  width: 8px;
  height: 8px;
  margin-top: -4px;
  margin-left: -4px;
  border-radius: 8px;
}

.custom-repeat-settings .mbsc-form-control-input {
  z-index: 0;
}

// wrrapping component with height

.card.widgets {
  .card-body > .card-title {
    background: $accent-color;
    border-radius: 15px 15px 0 0 !important;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 15px;
    font-weight: 500;
    transition: 400ms;
    font-family: "Ubuntu", "Poppins";
  }
}
.card-header.cardHeaderUsed,
.card-header.customCardHeader {
  height: 4rem;
  align-items: center;
  justify-content: center;
  font-family: "Ubuntu", "Poppins";
  background-color: #dee2e6 !important;
}

.card-title.cardTitleUsed,
.card-title.customCardTitle,
.card-title.customCardTitle div span {
  font-family: "Ubuntu", "Poppins";
  font-size: 18px;
  align-items: center;
  color: #6c5b7b;
  background-color: transparent;
}
.page-not-found {
  height: var(--app-height);
  width: 100vw;
  img {
    height: 75%;
  }
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(300px + 1rem);
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #008f68;
  color: #fae042;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

.docg-schedule-card.docg-reschedule {
  margin: 0 !important;
}
.docg-disabled {
  opacity: 0.6;
}
.ag-paging-row-summary-panel {
  display: none;
}

.follow-up-dialog-modal {
  overflow: hidden;
  height: var(--app-height);

  .modal-dialog {
    max-height: calc(var(--app-height) - 100px);
    height: 100%;
    overflow: hidden;
    .modal-content {
      height: 100%;
      overflow: hidden;
      .modal-body {
        height: 100%;
        overflow: auto;
      }
    }
  }
}
.docg-parent-card {
  border-radius: 10px;
  background-color: #fff;
  height: calc(100vh - 100px);
  margin: 0 1rem !important;
}

.docg-select-list {
  li:nth-child(even) {
    // background-color: #eee;
  }
}
.docg-add-patient {
  & > .docg-card-body {
    height: 100%;
    overflow: hidden;
    & > .tab-content {
      height: 100%;
      overflow: auto;
    }
  }
}

.docg-add-patient .mbsc-ms-bottom.mbsc-scv-ctx,
.docg-add-patient .mbsc-windows.mbsc-ms-c.mbsc-ms-top.mbsc-scv-ctx {
  width: fit-content;
}
.records-display-new {
  display: flex;
  align-items: center;
  width: 10rem;
  position: absolute;
  bottom: 0rem;
  height: 48px;
  z-index: 1;
  order: 1;
  margin-left: 2.8rem;
  span {
    font-weight: 500;
    color: #6d6d6d;
  }
  .mbsc-ios.mbsc-select.mbsc-ltr {
    padding: 0 !important;
  }
  .record-selection-dropdown {
    position: relative;
    cursor: pointer;
    input.mbsc-ios.mbsc-select.mbsc-ltr {
      border-bottom: 1.5px solid #c06c84;
      border-radius: 0;
      font-weight: 500;
      width: 3rem;
      color: #c06c84;
      cursor: pointer;
      padding: 0.8em 0 0 4px !important;
      height: 2.5em !important;
    }
    .mbsc-ios.mbsc-select-icon.mbsc-ltr {
      color: #c06c84;
    }
  }
}
@media all and (max-width: 567px) {
  .records-display-new {
    bottom: -1rem;
    // right: -2.8rem;
  }
}
.docg-table {
  tr {
    font-size: 12px;
    th,
    td {
      border: 1px solid #000;
      flex: 1 1;
    }
  }
}
.custom-repeat-radio-cont .mbsc-ios.mbsc-ltr.mbsc-radio {
  padding: 1em !important;
  width: 30px;
  height: 30px;
  border: 2px solid #c06c84;
  border-radius: 50%;
  .mbsc-radio-box {
    left: auto;
    right: auto;
    &::after {
      left: -2px;
      border-color: #c06c84;
    }
  }
}
.docg-card.docg-pricing {
  max-height: 410px;
  height: 300px;
  width: auto;
  max-width: 600px;
  overflow: auto;
    color: #727272;
    line-height: 16px;
    font-size: 13px;
    span {
      font-weight: 500;
    }
    h5 {
      font-weight: bold;
    }
    ul {
      text-align: left;
      padding-left: 0;
      font-size: 13px;
      line-height: 14px;
    }
  .description{
    font-size: 12px;
    line-height: 14px;
  }
}
.gap-2 {
  gap: 2px;
}
.gap-6 {
  gap: 6px;
}
.logo-upload-area {
  height: calc(var(--app-height) * (0.2));
  width: calc(var(--app-height) * (0.2));
  position: relative;
  display: inline-block;

  border: 2px dashed #c06c84;
  .close {
    position: absolute;
    top: -11px;
    right: -11px;
    cursor: pointer;
  }
  img {
    max-height: 100% !important;
    max-width: 100% !important;
    position: relative;
    display: inline-block;
  }
}

.gap-4{
  gap: 16px;
}

.text-pink{
  color:#c06c84 !important;
}
.text-purple{
  color:#6c5b7b !important;
}


.tnc.custom-repeat-checkbox-cont .mbsc-checkbox.mbsc-form-control-wrapper{
  padding-right: 0 !important;
}

.docg-plan{
  text-align: left;
  font-family: "Poppins";
.upper-portion{
  gap: 16px
}
  .title{
    font-weight: bold;
    // color: #000;
    font-family: unset;
  }
  .pricing{
    color: #c06c84;
    font-size: xx-large;
    &.patients{
      font-size: large;
    }
  }
}
a{
  color: #000;
}


.patient-wrapper {
  // padding: 0 1em;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: inherit;
  .docg-patient-body {
    padding: 1rem 0.5rem;
  }
  .patient-tab {
    flex: 1 1 auto;
    overflow: hidden;
    box-shadow: 0px 0px 12px 0px rgb(0 0 0 / 40%) !important;
    margin-top: 0.75rem;
    border-radius: 17px !important;
  }

  & > .mbsc-col > .docg-card {
    height: 100%;
    margin: 0 !important;
    border-radius: 25px !important;
    & > .docg-card-content {
      height: 100%;
      padding-left: 0;
      padding-right: 0;
      .docg-patient-body {
        height: calc(100% - 42px);
        overflow: auto;
        overflow-x: hidden;
        padding: 0 1em;
        .mbsc-col-12 {
          padding: 0;
        }
      }
    }
  }
}
.docg-notes .docg-patient-form > .mbsc-grid > .mbsc-row {
  margin-bottom: 1rem;
}
.docg-notes .mbsc-windows.mbsc-ms-c {
  background: transparent !important;
}
.docg-medical-record-date {
  .mbsc-ios.mbsc-textfield-wrapper-underline {
    background: $accent-color !important;
  }
}

.history-dialog {
  width: 100%;
}

.docg-save-btn {
  background: $accent-color;
  padding: 0.5rem 1.5rem;
  display: flex;
  align-items: center;
  border-radius: 25px;
  border: 0;

  img {
    height: 20px;
    margin-right: 7px;
    width: auto !important;
  }
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins", serif;
}
.docg-patient-cancel-btn {
  @include docg-flex-row;
  border: 1px solid $accent-color;
  padding: 5px 25px;
  background: white;
  border-radius: 25px;

  span {
    color: $accent-color;
    font-size: 14px;
    font-weight: 500;
    font-family: "Poppins", serif;
  }
}
.symptom {
  height: 100%;
}
.docg-message-list {
  .mbsc-ios .mbsc-lv-item:before,
  .mbsc-ios .mbsc-lv-item:after {
    border: 0;
  }
}

.patient-wrapper > .mbsc-col > .mbsc-card {
  //   height: 100%;
  //   overflow: auto;
  //   margin: 0 !important;
}

.docg-patient-form {
  .mbsc-ms-top.mbsc-scv-ctx,
  .mbsc-ms-bottom.mbsc-scv-ctx,
  .mbsc-windows.mbsc-ms-c {
    position: relative !important;
    background: transparent;
  }
}

.add-more-disease {
  @include docg-flex-row;

  padding-left: 10px;

  border-radius: 10px;
  width: 100%;
  height: 40px;
  background-color: $title-bg-color;

  img {
    max-width: 20px;
  }
  span {
    font-size: 1rem;
    font-weight: 500;
    font-family: "Poppins", serif;
    color: $title-color;
  }
}

.docg-patient-form-wrapper {
  overflow: hidden;
  background: #fff !important;
  & [class*="mbsc-col-"] {
    margin-top: 0.75rem !important;
  }
  .card-title {
    background: $title-bg-color;
    font-size: 1.2rem;
    color: $title-color;
    padding: 0.5em 1em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0 !important;
    font-family: "Ubuntu", "Poppins";
    img {
      max-width: 20px;
      height: 20px;
    }
  }
  .card-body {
    margin: 1rem 0;
    background-color: #f5f5f5;
    padding: 0;
    background: transparent;
    overflow: auto;
    margin-top: 0;
  }
  .docg-patient-form {
    label,
    input,
    select {
      ::placeholder {
        color: #929292;
        font-size: 14px;
        font-weight: 600;
        opacity: 1;
        font-family: "Poppins", serif;
      }
    }
  }
  .mbsc-select-input {
    background: transparent !important;
    border-bottom: 1px solid #aaaaaa;
    margin-bottom: 1px !important;
    color: #929292;
    font-size: 1rem;
    font-weight: 600;
    font-family: "Poppins", serif;
  }
}
.docg-patient-form-wrapper:hover .card-title {
  background: #dee2e6;
}
.add-more-drug {
  border-bottom: 1px solid #aaaaaa;
  margin-bottom: 1px !important;
  display: flex;
  align-items: center;
  flex-direction: row;
  button {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    color: #c06c84;
    font-size: 12px;
    font-family: "Poppins", serif;
    background: transparent;
    img {
      width: 20px !important;
      height: 20px;
      margin-right: 5px;
    }
  }
}

.patient-permission-modal {
  .btn-primary:focus {
    border: none;
    background-color: inherit;
    border-color: none;
  }
  padding: 3rem 5rem;
  & > img {
    margin: 0.5rem;
  }
  h4 {
    font-size: 22px;
    font-weight: 600;
    font-family: "Poppins", serif;
    color: $title-color;
    margin: 1rem 0;
  }
  // width: 30vw;
  // max-width: 600px;
  // height: 40vh;
}
.medical-record-list {
  .mbsc-ms-item.mbsc-ms-item-sel {
    padding: 0 !important;
  }
}
.docg-popup-container {
  .mbsc-popup-padding {
    padding: 0 !important;
  }
  .docg-popup-title {
    background-color: #d3d3d3;
    padding: 5px 10px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  .mbsc-grid {
    padding: 0 1rem;
  }
  .mbsc-accordion {
    width: 100%;
  }
  .mbsc-row {
    margin: 0;
  }
}
.history-card.active {
  .mbsc-card-title button {
    color: #fff !important;
  }
  .docg-card {
    border: 1px solid $accent-color !important;
  }
  .docg-card-title {
    background-color: $accent-color !important;
    color: #fff;
  }
  h5 {
    color: $accent-color !important;
  }
  .history-card-timeline .history-card-timeline-pointer {
    background: $accent-color;
  }
}
.symptom-btn {
  background-color: $accent-color;
  display: flex;
  // height: 40px;
  align-items: center;
  justify-content: center;
  align-self: center;
  float: right;
  color: #fff;
  padding: 7px 0rem !important;
  border-radius: 25px;
  max-width: 10rem !important;
  margin-left: 1em;
  img {
    // height: 30px;
    max-width: 22px;
    margin-right: 5px;
  }
}

.docg-message-list {
  .mbsc-ios .mbsc-lv {
    background-color: transparent;
    padding: 0 30px;
  }
  h3 {
    font-family: "Poppins", serif;
    color: $primary-bg-color;
  }

  .item-left {
    padding: 0px 20px;
    background: transparent;

    // h3 span {
    //   background: $primary-bg-color;
    //   border-radius: 10px;
    //   padding: 0 5px;
    //   color: #fff;
    //   min-width: 30px;
    // }
    h3 {
      // border: 1px solid rgb(242, 226, 230);
      // color: #fff;
      border-radius: 10px;
      display: inline-block;
      padding: 10px 20px;
      font-size: 18px;
    }
  }
  .item-right {
    padding: 0px 20px 10px 20px;
    background: transparent;

    // h3 span {
    //   background: $accent-color;
    //   border-radius: 10px;
    //   padding: 0 5px;
    //   color: #fff;
    //   min-width: 30px;
    // }
    h3 {
      display: inline-block;

      // border: rgb(226, 222, 229);
      border-radius: 10px;
      padding: 5px 20px;
      font-size: 16px;
    }
  }
  .card-1 {
    // border: 1px solid $primary-bg-color;
    box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 17%) !important;
    border-radius: 25px;
    margin: 15px 0;
  }
}
.mbsc-collapsible .mbsc-collapsible-content {
  padding: 0 10px;
}
.docg-patient-form {
  .observation {
    border-bottom: 1px solid #aaaaaa;
  }
  .mbsc-row {
    margin: 0;
  }
}

.docg-tabs.docg-rounded-tabs {
  margin-top: 20px;
  margin-bottom: 10px;
  background: transparent !important;
  .mbsc-scv-item.mbsc-btn-e {
    max-width: 200px;
    border-radius: 20px;
    background-color: #dee2e6;
    margin: 0 10px;
  }
}

.followup-modal {
  width: 80vw;
  max-width: 80vw;
  max-width: 1000px;
}

.docg-modal {
  .modal-content {
    max-height: 80vh;
    overflow: hidden;
    .docg-card {
      height: 100%;
      overflow: hidden;
      .card-body {
        height: 100%;
        overflow: auto;
      }
    }
  }
}

.medical-record {
  .date-picker-medical {
    .mbsc-ios.mbsc-textfield-wrapper-outline,
    .mbsc-ios.mbsc-textfield-wrapper-box {
      margin: 0 10px;
      height: 30px;
      & input {
        height: 30px;
      }
    }
  }
}

.docg-patient-sidebar {
  margin: 0 !important;
  .section {
    margin-left: 7px;
    padding-left: 7px;
    border-left: 2px solid $accent-color;
  }
  .docg-card-content {
    font-family: "Poppins", serif;
    font-size: 11px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .section:last-child {
      justify-self: flex-end;
    }
    .docg-patient-name {
      color: $primary-bg-color;
      font-weight: bold;
    }
    .docg-patient-label {
      color: #7b7b7b;
    }
    .docg-patient-info {
      color: #000;
    }
  }

  .docg-card-image {
    width: 30px !important;
    height: 30px !important;
    border-radius: 50%;
    border: 2px solid $accent-color;
    margin-left: 5px;
  }

  .docg-patient-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: transparent;
    margin: 0 6px;
    padding: 0;
    .img {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #6d5c7c;
      border-radius: 1rem;
      width: 25px;
      height: 16.5px;
      img {
        width: 8px;
        height: 8px;
      }
    }
    span {
      color: #6c5b7b;
    }
  }

  .docg-patient-options.docg-pink {
    .img {
      background: #c06c84;
    }
    span {
      color: #c06c84;
    }
  }

  .docg-patient-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: #7b7b7b !important;
    padding: 0 !important;
    margin: 0 !important;
    & img {
      margin-left: 8px;
      width: 8px !important;
      height: 8px !important;
    }
  }
}

.docg-checkbox {
  .mbsc-ios .mbsc-checkbox-box {
    border: 0.125em solid #d36887;
    left: 0;
  }
  .mbsc-control-w .mbsc-label {
    margin-left: 1rem;
  }
  .mbsc-ios .mbsc-checkbox-box:after {
    border: 0.125em solid #d36887;
    border-top: 0;
    border-right: 0;
  }
}

.docg-referredto {
  .others {
    text-transform: uppercase;
    color: #757575;
    font-size: 16px;
    font-weight: 600;
  }
}

.patient-wrapper {
  .docg-lab-test-list {
    &:first-child {
      margin-top: 0 !important;
    }
    transition: all 1s ease-in-out;
    cursor: pointer;

    .collapsed {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: 10px;
      width: 100%;
      padding: 10px 20px;
      background-color: #dee2e6;
      box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 17%) !important;
      img {
        max-width: 20px;
        max-height: 20px;
      }
      .title span {
        font-size: 1.1rem;
        font-weight: 600;
        font-family: "Poppins", serif;
        color: #6c5b7b;
      }
    }
  }
}
.docg-select-prescription {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 0.5rem !important;

  .card {
    flex-grow: 1;
    min-width: auto !important;
    background-color: #fff;
    margin: 0 !important;
    overflow: hidden;
    &:hover {
      .card-title {
        background: #dee2e6 !important;
        color: #6c5b7b !important;
      }
    }
  }

  .card-title {
    margin: 0;
    justify-content: center;
    background: #dee2e6;
    padding: 0.5rem 1.5rem;
    color: #6c5b7b;
    font-size: 18px;
    font-weight: 600;
    font-family: "Poppins", serif;
    line-height: 22px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    flex-direction: row;

    img {
      height: 22px;
      width: 22px !important;
      margin-right: 5px;
    }
  }
  .card-body {
    height: 100%;
    overflow: auto;
    background: #fff;
    padding: 0;
    margin: 0.5em;
    max-height: 90vh;
    input {
      border: none;
      border-bottom: 1px solid #dedede;
      font-size: 14px;
      font-weight: 500;
      color: #565656;
      &::placeholder {
        color: #929292;
      }
    }
    ul {
      list-style: none;
      height: calc(100% - 30px);
      padding: 0;
      li {
        font-size: 13px;
        font-family: "Poppins", serif;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0.5em 0;
        cursor: pointer;
        img {
          max-width: 15px;
          max-height: 15px;
        }
      }
    }
  }
}
.docg-patient-card {
  height: 100%;

  & > .docg-card-content {
    height: 100%;
    padding: 0em;

    & .docg-patient-body {
      height: calc(100% - 54px);
      overflow: auto;
      padding-top: 0;
    }
  }
}

.docg-bmi {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  padding-bottom: 1em;
  // border-bottom: 5px solid $accent-color;
  img {
    max-height: 88%;
    width: auto;
  }
  .active {
    max-height: 95%;
  }
}

.docg-patient-update {
  background: $accent-color;
  color: #fff;
  font-size: 12px;
}

.docg-range-select {
  label {
    width: 9.2em;
    border: none !important;
    border-radius: 10px;
    padding: 0 10px;
    background: $accent-color !important;
    margin: 0;
  }
  span {
    border: none !important;
  }
  input.mbsc-textfield {
    padding: 0 !important;
    color: #fff !important;
    font-family: "Poppins", serif;
    font-size: 12px;
    border: none !important;
    border-radius: 10px;
  }
  input::placeholder {
    color: #fff !important;
    opacity: 1;
  }
}

@media all and (max-width: 1024px) {
  .docg-patient-sidebar {
    .docg-card-content {
      font-size: 9px;
    }
  }
}
@media all and (max-width: 991px) {
  .docg-patient-sidebar {
    .docg-card-content {
      font-size: 7px;
    }
  }
}
.docg-dropdown {
  background: $accent-color;
  line-height: 20px !important;
  img {
    width: 19px;
  }
  span {
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    margin: 0 7px;
  }
  .mbsc-collapsible-icon {
    margin: 0 !important;
    top: 25%;
  }
}
.mbsc-ios.mbsc-form-control-wrapper:before,
.mbsc-ios.mbsc-form-control-wrapper:after {
  display: none;
}

.docg-add-btn {
  background: $accent-color;
  color: #fff;
  padding: 0.5rem 1.5rem;
  border-radius: 25px;
}

.mbsc-ms-txt .docg-tabs.mbsc-comp.history-tabs {
  border-bottom: 3px solid rgba(108, 91, 123, 0.3);
  .mbsc-scv-item.mbsc-btn-e {
    border-radius: 1rem;
    margin: 1rem 1rem;
    background: $accent-color;
    color: #fff;
    font-weight: 300;
    font-size: 1rem;
    border: 1px solid $accent-color;
    text-transform: uppercase;
    position: relative;
    &.mbsc-ms-item-sel {
      border-bottom: 3px solid $primary-bg-color;
    }
    &::after {
      position: absolute;
      content: "";
      width: 1rem;
      height: 1rem;
      background-color: $primary-bg-color;
      bottom: -1rem;
      left: auto;
    }
  }
}

.history-dialog-modal {
  height: var(--app-height);
  margin: 0;
  margin-left: auto;
  overflow: hidden;
  .modal-content {
    height: 100%;
    overflow: hidden;
    .modal-body {
      height: 100%;
      overflow: auto;
    }
  }
}

.patient-history {
  border-radius: 0.5em !important;
  & .card-title {
    border-radius: 0.37em 0.37em 0 0 !important;
  }
  .mbsc-ms-txt .docg-tabs.mbsc-comp .mbsc-scv-item.mbsc-btn-e:first-child {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }
  .mbsc-ms-txt .docg-tabs.mbsc-comp .mbsc-scv-item.mbsc-btn-e:last-child {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }
}
.eye-popup {
  .mbsc-col-2 {
    padding: 0 0.5rem;
  }
  .mbsc-row {
    margin-top: 1rem;
  }
  .mbsc-ios.mbsc-textfield-wrapper {
    border: 1px solid black;
    border-radius: 5px;
  }
  .mbsc-ios.mbsc-textfield {
    padding: 0 5px !important;
  }
  .mbsc-ios.mbsc-select-icon.mbsc-ltr,
  .mbsc-ios.mbsc-select-icon.mbsc-rtl {
    top: 0.625em;
    width: 15px;
  }
}
.past-diagnosis {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  button {
    height: max-content;
  }
}
